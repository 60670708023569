import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import ApolloProviderWithToken from './apollo/apollo-provider.tsx';
import { RouterProviderWithRole } from './routes/routes-config.tsx';
import LoadingScreen from './components/common/loading-screen/loading-screen.tsx';
import { EnvModEnum } from './utils/enums.ts';

if (EnvModEnum.DEV !== import.meta.env.MODE) {
  Sentry.init({
    dsn: 'https://75089863edf9e2b5c24e0b7d324909cb@o4507707369521152.ingest.de.sentry.io/4507707378171984',
    integrations: [
      Sentry.browserTracingIntegration({
        instrumentPageLoad: false,
      }),
    ],
    tracesSampleRate: 1.0,
    environment: import.meta.env.MODE,
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Auth0Provider
    domain={import.meta.env.VITE_AUTH0_DOMAIN}
    clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: import.meta.env.VITE_BASE_URL,
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    }}
    cacheLocation={'localstorage'}
  >
    <ApolloProviderWithToken>
      <Suspense fallback={<LoadingScreen />}>
        <RouterProviderWithRole />
      </Suspense>
    </ApolloProviderWithToken>
  </Auth0Provider>,
);
