import {
  createBrowserRouter,
  redirect,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Home from './home.tsx';
import ErrorPage from './error-page.tsx';
import Layout from './layout.tsx';
import Courses from './courses.tsx';
import Assignments from './assignments.tsx';
import UserProfile from './user-profile.tsx';
import { useSuspenseQuery } from '@apollo/client';
import { GET_ME } from '../apollo/user.ts';
import { CourseStatus, UserRole } from '../apollo/__generated__/graphql.ts';
import QuestionsBrowserPage from './admin/questions/questions-browser-page.tsx';
import CreateQuestion from './admin/questions/create-question.tsx';
import EditQuestion from './admin/questions/edit-question.tsx';
import QuestionsDrafts from './admin/questions/questions-drafts.tsx';
import QuizzesBrowser from './admin/quizzes-browser.tsx';
import QuizInformation from './admin/quiz-information.tsx';
import CreateQuiz from './admin/create-quiz.tsx';
import CoursesBrowser from './admin/courses/courses-browser.tsx';
import TestsBrowser from './admin/tests/tests-browser.tsx';
import CreateTest from './admin/tests/create-test.tsx';
import TestInformation from './admin/tests/test-information.tsx';
import ModuleInformation from './admin/tests/module-information.tsx';
import CreateCourse from './admin/courses/create-course.tsx';
import CourseInformation from './admin/courses/course-information.tsx';
import UsersBrowser from './admin/users/users-browser.tsx';
import UserInformation from './admin/users/user-information.tsx';
import ScriptsBrowser from './admin/tests/scripts/scripts-browser.tsx';
import CreateScript from './admin/tests/scripts/create-script.tsx';
import EditScript from './admin/tests/scripts/edit-script.tsx';
import StartTest from './start-test.tsx';
import TestAttemptPage from './test-attempt-page.tsx';
import Assets from './teacher/assets.tsx';
import ReviewTest from './review-test.tsx';
import StartQuiz from './start-quiz.tsx';
import { QuizAttemptPage } from './quiz-attempt-page.tsx';
import QuestionsReports from './questions-reports.tsx';
import Join from './join.tsx';
import { InspectTestAttempt } from './inspect-test-attempt.tsx';
import { AnswersViewPage } from './answers-view-page.tsx';
import QuizAttempts from './quiz-attempts.tsx';
import TestAttempts from './test-attempts.tsx';
import AssetsBrowser from './admin/assets/assets-browser.tsx';
import CreateAsset from './admin/assets/create-asset.tsx';
import AssetInformation from './admin/assets/asset-information.tsx';
import CourseForAdmin from './course-for-admin.tsx';
import CourseForStudent from './course-for-student.tsx';
import { CheckoutSuccess } from './checkout-success.tsx';
import { Checkout } from './checkout.tsx';
import { InspectQuizAttempt } from './inspect-quiz-attempt.tsx';
import { useCallback, useEffect } from 'react';
import { RoutesEnum as R, RoutesPermissionEnum as RP } from '../utils/enums.ts';
import WatermarkPage from './admin/watermark-page';

const routePermissionMap = {
  [RP.COURSES_USER_ID_COURSE_ID]: {
    path: RP.COURSES_USER_ID_COURSE_ID,
    element: <CourseForAdmin />,
  },
  [RP.INSPECT_TEST_ATTEMPT_ATTEMPT_ID]: {
    path: RP.INSPECT_TEST_ATTEMPT_ATTEMPT_ID,
    element: <InspectTestAttempt />,
  },
  [RP.INSPECT_QUIZ_ATTEMPT_ATTEMPT_ID]: {
    path: RP.INSPECT_QUIZ_ATTEMPT_ATTEMPT_ID,
    element: <InspectQuizAttempt />,
  },
  [RP.QUESTIONS_BROWSER]: {
    path: RP.QUESTIONS_BROWSER,
    element: <QuestionsBrowserPage />,
  },
  [RP.QUESTIONS_BROWSER_DRAFTS]: {
    path: RP.QUESTIONS_BROWSER_DRAFTS,
    element: <QuestionsDrafts />,
  },
  [RP.CREATE_QUESTION]: {
    path: RP.CREATE_QUESTION,
    element: <CreateQuestion />,
  },
  [RP.EDIT_QUESTION_QUESTION_ID]: {
    path: RP.EDIT_QUESTION_QUESTION_ID,
    element: <EditQuestion />,
  },
  [RP.QUIZZES_BROWSER]: {
    path: RP.QUIZZES_BROWSER,
    element: <QuizzesBrowser />,
  },
  [RP.QUIZZES_BROWSER_SLUG]: {
    path: RP.QUIZZES_BROWSER_SLUG,
    element: <QuizInformation />,
  },
  [RP.CREATE_QUIZ]: {
    path: RP.CREATE_QUIZ,
    element: <CreateQuiz />,
  },
  [RP.TESTS_BROWSER]: {
    path: RP.TESTS_BROWSER,
    element: <TestsBrowser />,
  },
  [RP.TESTS_BROWSER_TEST_SLUG]: {
    path: RP.TESTS_BROWSER_TEST_SLUG,
    element: <TestInformation />,
  },
  [RP.TESTS_BROWSER_TEST_SLUG_SECTION_ID_MODULE_ID]: {
    path: RP.TESTS_BROWSER_TEST_SLUG_SECTION_ID_MODULE_ID,
    element: <ModuleInformation />,
  },
  [RP.CREATE_TEST]: {
    path: RP.CREATE_TEST,
    element: <CreateTest />,
  },
  [RP.SCRIPTS_BROWSER]: {
    path: RP.SCRIPTS_BROWSER,
    element: <ScriptsBrowser />,
  },
  [RP.CREATE_SCRIPT]: {
    path: RP.CREATE_SCRIPT,
    element: <CreateScript />,
  },
  [RP.SCRIPTS_BROWSER_SCRIPT_ID]: {
    path: RP.SCRIPTS_BROWSER_SCRIPT_ID,
    element: <EditScript />,
  },
  [RP.COURSES_BROWSER]: {
    path: RP.COURSES_BROWSER,
    element: <CoursesBrowser key={'courses-browser'} />,
  },
  [RP.COURSES_BROWSER_ARCHIVE]: {
    path: RP.COURSES_BROWSER_ARCHIVE,
    element: (
      <CoursesBrowser
        status={CourseStatus.Archive}
        key={'courses-browser/archive'}
      />
    ),
  },
  [RP.CREATE_COURSE]: {
    path: RP.CREATE_COURSE,
    element: <CreateCourse />,
  },
  [RP.COURSES_BROWSER_COURSE_ID]: {
    path: RP.COURSES_BROWSER_COURSE_ID,
    element: <CourseInformation />,
  },
  [RP.USERS_BROWSER]: {
    path: RP.USERS_BROWSER,
    element: <UsersBrowser />,
  },
  [RP.USERS_BROWSER_USER_ID]: {
    path: RP.USERS_BROWSER_USER_ID,
    element: <UserInformation />,
  },
  [RP.QUESTIONS_REPORTS]: {
    path: RP.QUESTIONS_REPORTS,
    element: <QuestionsReports />,
  },
  [RP.ANSWERS_VIEW]: {
    path: RP.ANSWERS_VIEW,
    element: <AnswersViewPage />,
  },
  [RP.ASSETS]: {
    path: RP.ASSETS,
    element: <Assets />,
  },
  [RP.ASSETS_BROWSER]: {
    path: RP.ASSETS_BROWSER,
    element: <AssetsBrowser />,
  },
  [RP.CREATE_ASSET]: {
    path: RP.CREATE_ASSET,
    element: <CreateAsset />,
  },
  [RP.ASSETS_BROWSER_ASSET_ID]: {
    path: RP.ASSETS_BROWSER_ASSET_ID,
    element: <AssetInformation />,
  },
  [RP.WATERMARK]: {
    path: RP.WATERMARK,
    element: <WatermarkPage />,
  },
};

const userRoleRoutePermissions = {
  [UserRole.Admin]: Object.values(RP),
  [UserRole.Staff]: [
    RP.QUESTIONS_BROWSER,
    RP.QUESTIONS_BROWSER_DRAFTS,

    RP.TESTS_BROWSER,
    RP.TESTS_BROWSER_TEST_SLUG_SECTION_ID_MODULE_ID,

    RP.QUIZZES_BROWSER,
    RP.QUIZZES_BROWSER_SLUG,
    RP.CREATE_QUIZ,

    RP.COURSES_BROWSER,
    RP.COURSES_BROWSER_ARCHIVE,
    RP.CREATE_COURSE,
    RP.COURSES_BROWSER_COURSE_ID,
    RP.COURSES_USER_ID_COURSE_ID,

    RP.USERS_BROWSER,
    RP.USERS_BROWSER_USER_ID,
    RP.INSPECT_TEST_ATTEMPT_ATTEMPT_ID,
    RP.INSPECT_QUIZ_ATTEMPT_ATTEMPT_ID,

    RP.QUESTIONS_REPORTS,

    RP.ANSWERS_VIEW,
  ],
  [UserRole.Sales]: [
    RP.COURSES_BROWSER,
    RP.COURSES_BROWSER_ARCHIVE,
    RP.CREATE_COURSE,
    RP.COURSES_BROWSER_COURSE_ID,
    RP.COURSES_USER_ID_COURSE_ID,

    RP.USERS_BROWSER,
    RP.USERS_BROWSER_USER_ID,
    RP.INSPECT_TEST_ATTEMPT_ATTEMPT_ID,
    RP.INSPECT_QUIZ_ATTEMPT_ATTEMPT_ID,
  ],
  [UserRole.Ta]: [
    RP.QUESTIONS_BROWSER,
    RP.QUESTIONS_BROWSER_DRAFTS,

    RP.TESTS_BROWSER,
    RP.CREATE_TEST,
    RP.TESTS_BROWSER_TEST_SLUG,
    RP.TESTS_BROWSER_TEST_SLUG_SECTION_ID_MODULE_ID,

    RP.QUIZZES_BROWSER,
    RP.QUIZZES_BROWSER_SLUG,
    RP.CREATE_QUIZ,

    RP.COURSES_USER_ID_COURSE_ID,
    RP.COURSES_BROWSER,
    RP.COURSES_BROWSER_ARCHIVE,
    RP.CREATE_COURSE,
    RP.COURSES_BROWSER_COURSE_ID,

    RP.USERS_BROWSER,
    RP.USERS_BROWSER_USER_ID,
    RP.INSPECT_TEST_ATTEMPT_ATTEMPT_ID,
    RP.INSPECT_QUIZ_ATTEMPT_ATTEMPT_ID,

    RP.ANSWERS_VIEW,
  ],
  [UserRole.Va]: [
    RP.QUESTIONS_BROWSER,
    RP.QUESTIONS_BROWSER_DRAFTS,
    RP.CREATE_QUESTION,
    RP.EDIT_QUESTION_QUESTION_ID,
  ],
  [UserRole.Teacher]: [
    RP.ASSETS,

    RP.QUESTIONS_BROWSER,
    RP.QUESTIONS_BROWSER_DRAFTS,
    RP.CREATE_QUESTION,
    RP.EDIT_QUESTION_QUESTION_ID,

    RP.QUESTIONS_REPORTS,

    RP.QUIZZES_BROWSER,
    RP.QUIZZES_BROWSER_SLUG,
    RP.CREATE_QUIZ,

    RP.TESTS_BROWSER,
    RP.TESTS_BROWSER_TEST_SLUG,
    RP.TESTS_BROWSER_TEST_SLUG_SECTION_ID_MODULE_ID,
    RP.CREATE_TEST,

    RP.SCRIPTS_BROWSER,
    RP.CREATE_SCRIPT,
    RP.SCRIPTS_BROWSER_SCRIPT_ID,

    RP.COURSES_BROWSER,
    RP.COURSES_BROWSER_ARCHIVE,
    RP.COURSES_USER_ID_COURSE_ID,
    RP.CREATE_COURSE,
    RP.COURSES_BROWSER_COURSE_ID,

    RP.USERS_BROWSER,
    RP.USERS_BROWSER_USER_ID,
    RP.INSPECT_TEST_ATTEMPT_ATTEMPT_ID,
    RP.INSPECT_QUIZ_ATTEMPT_ATTEMPT_ID,

    RP.ANSWERS_VIEW,
  ],
  [UserRole.Student]: [],
};

const userRouter = (otherRoutesByUserRole: RouteObject[]) => {
  return createBrowserRouter([
    {
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: R.HOME,
          element: <Home />,
        },
        {
          // Redirect to home page from old auth path
          path: R.API_AUTH_LOGIN,
          loader: () => redirect('/'),
        },
        {
          path: R.ASSIGNMENTS,
          element: <Assignments />,
        },
        {
          path: R.COURSES,
          element: <Courses />,
        },
        {
          path: R.COURSES_COURSE_ID,
          element: <CourseForStudent />,
        },
        {
          path: R.PROFILE,
          element: <UserProfile />,
        },
        {
          path: R.START_TEST,
          element: <StartTest />,
        },
        {
          path: R.TEST_ATTEMPT_ATTEMPT_ID,
          element: <TestAttemptPage />,
        },
        {
          path: R.REVIEW_TEST_ATTEMPT_ID,
          element: <ReviewTest />,
        },
        {
          path: R.START_QUIZ,
          element: <StartQuiz />,
        },
        {
          path: R.QUIZ_ATTEMPT_ATTEMPT_ID,
          element: <QuizAttemptPage />,
        },
        {
          path: R.JOIN_INVITE_ID,
          element: <Join />,
        },
        {
          path: R.QUIZ_ATTEMPTS_QUIZ_SLUG,
          element: <QuizAttempts />,
        },
        {
          path: R.TEST_ATTEMPTS_TEST_SLUG,
          element: <TestAttempts />,
        },
        {
          path: R.CHECKOUT,
          element: <Checkout />,
        },
        {
          path: R.CHECKOUT_SUCCESS,
          element: <CheckoutSuccess />,
        },
        ...otherRoutesByUserRole,
      ],
    },
  ]);
};

export const RouterProviderWithRole = () => {
  const { data } = useSuspenseQuery(GET_ME, { errorPolicy: 'all' });
  const user = data?.user.me;

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, name: user.name, email: user.email });
    }
  }, [user]);

  const otherRoutesByUserRole = userRoleRoutePermissions[
    user?.roles[0] || UserRole.Student
  ].map((permission) => routePermissionMap[permission]);

  const useDetermineRouter = () => {
    return useCallback(() => {
      return userRouter(otherRoutesByUserRole);
    }, []);
  };

  const determineRouter = useDetermineRouter();

  return <RouterProvider router={determineRouter()} />;
};
