import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuestionSort } from '../questions-hooks.tsx';
import { useQuestionFilter } from './questions-filter/filter-utils.ts';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_QUESTIONS,
  QUESTION_TABLE_FRAGMENT,
  REMOVE_QUESTION,
} from '../../../apollo/questions.ts';
import { useFragment } from '../../../apollo/__generated__';
import { uniqBy } from 'lodash';
import QuestionsFilter from './questions-filter.tsx';
import { questionsBrowserFilters } from './questions-filter/filter-constants.ts';
import QuestionsTable from './questions-table.tsx';
import {
  EntityCreatedRole,
  QuestionAnswerType,
  QuestionStatus,
} from '../../../apollo/__generated__/graphql.ts';
import ActionsDropdown from '../../table/actions-dropdown.tsx';
import {
  useDeleteItems,
  useFetchMore,
  useSelectItem,
} from '../../../utils/hooks.ts';
import QuestionIdTableComponent from './question-id-table-component.tsx';
import Difficulty from '../../table/difficulty.tsx';
import Tag from '../../common/tag.tsx';
import AnswerCell from './answer-cell.tsx';
import Markdown from '../../markdown.tsx';
import { capitalizeWords } from '../../../utils/capitalize-words.ts';
import useToggleContentCreator from '../../common/use-toggle-content-creator.tsx';
import { NavBarEnum } from '../../../utils/enums.ts';

interface IQuestionsBrowser {
  isQuestionsDrafts?: boolean;
  isModalMode?: boolean; // modal for add questions in quiz
  setSelectedQuestion?: Dispatch<SetStateAction<number[]>>;
}
const QuestionsBrowser = ({
  isQuestionsDrafts = false,
  isModalMode = false,
  setSelectedQuestion,
}: IQuestionsBrowser) => {
  const FETCH_LIMIT = 20;
  const { ContentToggle, isShowTeacherContent, isContentToggleAvailable } =
    useToggleContentCreator();

  const [page, setPage] = useState<number>(1);
  const [sort, setQuestionSort] = useQuestionSort();
  const { setIsDeleted, onDelete } = useDeleteItems();

  const resetTable = () => setPage(1);

  const {
    filterInput,
    filters,
    setFilters,
    onAddFilter,
    onRemoveFilter,
    onClearFilter,
  } = useQuestionFilter(resetTable, {
    status: isQuestionsDrafts ? [QuestionStatus.Draft] : undefined,
  });

  const { data, loading, fetchMore } = useQuery(GET_QUESTIONS, {
    fetchPolicy: 'network-only',
    variables: {
      paging: {
        page: 1,
        limit: FETCH_LIMIT,
      },
      sort,
      filter: {
        ...filterInput,
        created: isContentToggleAvailable
          ? isShowTeacherContent
            ? undefined
            : EntityCreatedRole.Nalaprep
          : undefined,
      },
    },
  });

  const questionsFragment = useFragment(
    QUESTION_TABLE_FRAGMENT,
    data?.question.getQuestions.results,
  );

  const { selected, onSelect, onSelectAmount, onResetAll } = useSelectItem(
    questionsFragment?.map((question) => question.id) || [],
    setSelectedQuestion,
  );

  const [remove] = useMutation(REMOVE_QUESTION, {
    refetchQueries: [GET_QUESTIONS],
  });
  const onRemoveQuestion = (ids: number[]) => {
    remove({
      variables: {
        input: {
          ids,
        },
      },
    }).then(() => {
      onResetAll();
      setPage(1);
      setIsDeleted(true);
    });
  };

  useEffect(() => {
    resetTable();
  }, [sort, isShowTeacherContent]);

  const onFetchMore = (setFetchMore: Dispatch<SetStateAction<boolean>>) => {
    const total = Number(data?.question.getQuestions.total);
    if (total && Number(questionsFragment?.length) < total) {
      const nextPage = page + 1;
      setPage(nextPage);
      setFetchMore(true); // function fetchMore doesnt trigger loading status...
      return fetchMore({
        variables: {
          paging: {
            page: nextPage,
            limit: FETCH_LIMIT,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.question?.getQuestions?.results) return prev;

          const union = [
            ...(prev.question?.getQuestions?.results || []),
            ...fetchMoreResult.question.getQuestions.results,
          ];
          const uniqueQuestions = uniqBy(union, 'id');

          if (uniqueQuestions.length !== union.length)
            console.error('duplicates on merge detected'); // TODO remove after dev stage

          return {
            question: {
              ...prev.question,
              getQuestions: {
                ...fetchMoreResult.question.getQuestions,
                results: uniqueQuestions,
              },
            },
          };
        },
      });
    }
  };
  const { ref, isFetchMore, setFetchMore } = useFetchMore(
    onFetchMore,
    onDelete,
  );

  const getNext = async (id: number) => {
    const index = questionsFragment?.findIndex(
      (question) => question.id === id,
    );
    const nextId = questionsFragment?.[index! + 1]?.id;
    return {
      id:
        nextId ||
        (await onFetchMore(setFetchMore))?.data.question.getQuestions.results[0]
          ?.id,
      index: Number(index) + 1,
    };
  };

  const getPrev = (id: number) => {
    const index = questionsFragment?.findIndex(
      (question) => question.id === id,
    );
    return {
      id: questionsFragment?.[index! - 1]?.id,
      index: Number(index) - 1,
    };
  };

  return (
    <div>
      <section className={'mt-2 flex justify-between'}>
        <div className={'grid grid-cols-1 gap-1'}>
          {filters.map((filter, index, array) => {
            const isLast = index === array.length - 1;
            return (
              <div className={'flex items-center'} key={filter.id}>
                <QuestionsFilter
                  filter={filter}
                  currentFilters={filters}
                  setFilters={setFilters}
                  onAddFilter={
                    isLast && filters.length < questionsBrowserFilters.length
                      ? onAddFilter
                      : undefined
                  }
                  onRemoveFilter={
                    array.length === 1 ? onClearFilter : onRemoveFilter
                  }
                  except={isQuestionsDrafts ? 'status' : undefined}
                />
                {isContentToggleAvailable && isLast ? <ContentToggle /> : null}
              </div>
            );
          })}
        </div>

        <div
          className={'flex items-center justify-center gap-2 whitespace-nowrap'}
        >
          {!isModalMode && (
            <ActionsDropdown
              selected={selected}
              browser={NavBarEnum.QUESTIONS_BROWSER}
              onRemove={onRemoveQuestion}
            />
          )}

          <div className={'text-sm text-gray'}>
            <p>
              {data?.question.getQuestions?.total} questions match your filters
            </p>
            <p>{selected.length} questions selected</p>
          </div>
        </div>
      </section>

      <hr className={'my-2 opacity-10'} />

      <QuestionsTable
        selected={selected}
        onSelectAmount={onSelectAmount}
        onResetAll={onResetAll}
        sort={sort}
        setSort={setQuestionSort}
        isLoading={loading || isFetchMore}
        isEmpty={!questionsFragment?.length}
      >
        {questionsFragment?.map((question, index, array) => {
          const isChoiceType =
            question.answerType !== QuestionAnswerType.TextEntry;
          return (
            <tr key={question.id} ref={index === array.length - 3 ? ref : null}>
              <td className="flex text-center">
                <input
                  type={'checkbox'}
                  checked={selected.includes(question.id)}
                  onChange={() => onSelect(question.id)}
                />
              </td>

              <td>
                <QuestionIdTableComponent
                  id={question.id}
                  displayValue={question.originalId}
                  index={index}
                  total={data?.question.getQuestions?.total ?? 0}
                  getNext={getNext}
                  getPrev={getPrev}
                />
              </td>

              <td>
                <AnswerCell field={'answer'} question={question} />
              </td>
              <td>{capitalizeWords(question.usageType || '')}</td>
              <td>
                <Markdown>{question.content ?? ''}</Markdown>
              </td>
              <td>
                <Markdown>{question.prompt}</Markdown>
              </td>

              <td>
                <Difficulty level={question.difficulty.id} />
              </td>
              <td>{capitalizeWords(question.skill?.name)}</td>
              <td>{isChoiceType ? 'Multiple' : 'Text'}</td>
              <td>{capitalizeWords(question.domain?.name)}</td>
              <td>{capitalizeWords(question.subject?.name)}</td>
              <td>{capitalizeWords(question.type1?.name)}</td>

              <td>{capitalizeWords(question.type2?.name)}</td>
              <td>
                <div className={'flex flex-nowrap'}>
                  {question.tags?.map((tag, index) => (
                    <Tag title={capitalizeWords(tag.name)} key={index} />
                  ))}
                </div>
              </td>

              <td>
                <AnswerCell field={'a'} question={question} />
              </td>
              <td>
                <AnswerCell field={'b'} question={question} />
              </td>
              <td>
                <AnswerCell field={'c'} question={question} />
              </td>
              <td>
                <AnswerCell field={'d'} question={question} />
              </td>
              <td>{capitalizeWords(question.sourceType || '')}</td>
              <td>{question.sourceQuestion?.originalId}</td>
              <td>{question.explanation}</td>
              <td>{question.created}</td>
            </tr>
          );
        })}
      </QuestionsTable>
    </div>
  );
};
export default QuestionsBrowser;
