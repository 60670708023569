import RemoveIcon from '../../assets/icons/remove.svg?react';
import ActionsIcon from '../../assets/icons/actions.svg?react';
import PlusIcon from '../../assets/icons/plus.svg?react';
import UserPlusIcon from '../../assets/icons/user-plus.svg?react';
import CourseGrayIcon from '../../assets/icons/course-gray.svg?react';
import { Menu } from '@headlessui/react';
import { useCallback, useState } from 'react';
import Modal from '../common/modal.tsx';
import CreateQuizForm from '../../routes/admin/create-quiz-form.tsx';
import UsersBrowser from '../../routes/admin/users/users-browser.tsx';
import Button from '../common/button.tsx';
import { FetchResult, useMutation } from '@apollo/client';
import { ASSIGN_ASSETS, ASSIGN_TO_USERS } from '../../apollo/user.ts';
import { toast } from 'react-toastify';
import CoursesBrowser from '../../routes/admin/courses/courses-browser.tsx';
import { ATTACH_ELEMENTS_COURSE } from '../../apollo/courses.ts';
import {
  IQuestionsExportSettingsFormData,
  QuestionsExportSettingsForm,
} from '../questions/questions-export-settings-form.tsx';
import { useGetMe } from '../../utils/hooks.ts';
import {
  browserWatcher,
  createPdfExportUrl,
} from '../../utils/common-utils.ts';
import {
  AssignAssetsToUserMutation,
  AssignToUserMutation,
  UserRole,
} from '../../apollo/__generated__/graphql.ts';
import { NavBarEnum } from '../../utils/enums.ts';

type IActionOptions =
  | 'assign to quiz'
  | 'assign to course'
  | 'assign to users'
  | 'export to pdf';
interface IActionsDropdown {
  selected: number[];
  onRemove?: (id: number[]) => void;
  browser: NavBarEnum;
}
const ActionsDropdown = ({ selected, onRemove, browser }: IActionsDropdown) => {
  const { isAdmin, isTeacher } = useGetMe();
  const [assign] = useMutation(ASSIGN_TO_USERS);
  const [assignAssets] = useMutation(ASSIGN_ASSETS);
  const [attachToCourse] = useMutation(ATTACH_ELEMENTS_COURSE);
  const [modal, setModal] = useState<IActionOptions | null>(null);
  const [items, setItems] = useState<number[]>([]);
  const onCloseModal = () => setModal(null);

  const {
    isAssetsBrowser,
    isQuestionsBrowser,
    isQuizzesBrowser,
    isTestsBrowser,
  } = browserWatcher(browser);

  const onAssignToUsers = () => {
    let promise: Promise<
      FetchResult<AssignToUserMutation | AssignAssetsToUserMutation>
    >;
    if (isAssetsBrowser) {
      promise = assignAssets({
        variables: {
          input: {
            userIds: items,
            assetIds: selected,
          },
        },
      });
    } else {
      promise = assign({
        variables: {
          input: {
            userIds: items,
            questionIds: isQuestionsBrowser ? selected : undefined,
            quizIds: isQuizzesBrowser ? selected : undefined,
            testIds: isTestsBrowser ? selected : undefined,
          },
        },
      });
    }
    toast
      .promise(promise, {
        pending: `Assigning...`,
        success: `Success`,
      })
      .then(() => onCloseModal());
  };

  const onAssignToCourse = () => {
    const promise = attachToCourse({
      variables: {
        input: {
          ids: items,
          quizIds: isQuizzesBrowser ? selected : undefined,
          testIds: isTestsBrowser ? selected : undefined,
        },
      },
    });

    toast
      .promise(promise, {
        pending: `Assigning...`,
        success: `Success`,
      })
      .then(() => onCloseModal());
  };

  const isHasPermissionToAssignToUser = (): boolean => {
    switch (browser) {
      case NavBarEnum.QUESTIONS_BROWSER:
        return isAdmin || isTeacher || false;
      case NavBarEnum.TESTS_BROWSER:
        return isAdmin || isTeacher || false;
      case NavBarEnum.QUIZZES_BROWSER:
        return isAdmin || isTeacher || false;
      default:
        return false;
    }
  };

  const isHasPermissionForDelete = (): boolean => {
    switch (browser) {
      case NavBarEnum.QUESTIONS_BROWSER:
        return isAdmin || isTeacher || false;
      case NavBarEnum.TESTS_BROWSER:
        return isAdmin || isTeacher || false;
      case NavBarEnum.QUIZZES_BROWSER:
        return isAdmin || isTeacher || false;
      default:
        return false;
    }
  };

  const isAssignToCourses = [
    NavBarEnum.TESTS_BROWSER,
    NavBarEnum.QUIZZES_BROWSER,
  ].includes(browser);
  const isExport = [
    NavBarEnum.TESTS_BROWSER,
    NavBarEnum.QUIZZES_BROWSER,
  ].includes(browser);

  const onExport = useCallback(
    (params: IQuestionsExportSettingsFormData) => {
      const type = isQuizzesBrowser ? 'quiz' : 'test';
      selected?.forEach((id) => {
        const url = createPdfExportUrl(type, {
          id: id.toString(),
          ...params,
        });
        window.open(url);
      });
      onCloseModal();
    },
    [browser, selected],
  );

  if (!selected.length) return null;
  return (
    <>
      <Menu as="div" className="relative inline-block text-center">
        <Menu.Button
          className={
            'flex w-32 select-none items-center justify-center rounded bg-light-blue p-1 py-1.5 text-sm font-medium text-black'
          }
        >
          <ActionsIcon className={'mr-2'} /> Actions
        </Menu.Button>
        <Menu.Items
          className={
            'absolute z-20 rounded bg-white p-2 text-sm font-normal shadow-lg'
          }
        >
          <p className="select-none p-2 px-4 text-start font-medium text-gray">
            Assign to
          </p>

          {isQuestionsBrowser && (
            <Menu.Item>
              <button
                className="my-0.5 flex w-full select-none rounded p-2 px-4 outline-none hover:bg-light-blue"
                onClick={() => setModal('assign to quiz')}
              >
                <PlusIcon className={'mr-2'} /> New Quiz
              </button>
            </Menu.Item>
          )}

          {isHasPermissionToAssignToUser() && (
            <Menu.Item>
              <button
                onClick={() => setModal('assign to users')}
                className="my-0.5 flex w-full select-none items-center rounded p-2 px-4 outline-none hover:bg-light-blue"
              >
                <UserPlusIcon className={'mr-2'} /> User
              </button>
            </Menu.Item>
          )}

          {isAssignToCourses && (
            <Menu.Item>
              <button
                onClick={() => setModal('assign to course')}
                className="my-0.5 flex w-full select-none items-center rounded p-2 px-4 outline-none hover:bg-light-blue"
              >
                <CourseGrayIcon className={'mr-2'} /> Course
              </button>
            </Menu.Item>
          )}

          {onRemove && isHasPermissionForDelete() && (
            <>
              <p className="mt-4 select-none p-2 px-4 text-start font-medium text-gray">
                Other
              </p>
              <Menu.Item>
                <button
                  disabled={!isAdmin && !isTeacher}
                  onClick={() => onRemove(selected)}
                  className="my-0.5 flex w-full select-none rounded p-2 px-4 outline-none hover:bg-light-blue disabled:grayscale"
                >
                  <RemoveIcon className={'mr-2'} stroke={'#F05252'} /> Delete
                </button>
              </Menu.Item>
            </>
          )}

          {!!selected?.length && isExport && (
            <Menu.Item>
              <button
                onClick={() => setModal('export to pdf')}
                className="my-0.5 flex w-full select-none rounded p-2 px-4 outline-none hover:bg-light-blue"
              >
                Export
              </button>
            </Menu.Item>
          )}
        </Menu.Items>
      </Menu>

      {modal === 'assign to quiz' && (
        <Modal
          title={'Create new quiz'}
          description={`You are about to create a quiz with ${selected.length} questions.`}
          onClose={onCloseModal}
          isOpen={!!modal}
        >
          <CreateQuizForm questions={selected} />
        </Modal>
      )}

      {modal === 'assign to users' && (
        <Modal
          title={'Assign to users'}
          onClose={onCloseModal}
          isOpen={!!modal}
        >
          <Button
            onClick={onAssignToUsers}
            disabled={!items.length}
            className={'ml-auto mt-2'}
          >
            Assign ({items.length})
          </Button>
          <UsersBrowser
            isModalMode={true}
            setSelectedUsers={setItems}
            defaultFilter={
              isAssetsBrowser ? { role: UserRole.Teacher } : undefined
            }
          />
        </Modal>
      )}

      {modal === 'assign to course' && (
        <Modal
          title={'Assign to courses'}
          onClose={onCloseModal}
          isOpen={!!modal}
        >
          <>
            <Button
              onClick={onAssignToCourse}
              disabled={!items.length}
              className={'ml-auto mt-2'}
            >
              Assign ({items.length})
            </Button>
            <CoursesBrowser setSelectedCourses={setItems} />
          </>
        </Modal>
      )}

      {modal === 'export to pdf' && (
        <Modal title={'Export to PDF'} onClose={onCloseModal} isOpen={!!modal}>
          <div className="mt-3 w-80">
            <QuestionsExportSettingsForm onSubmit={onExport} />
          </div>
        </Modal>
      )}
    </>
  );
};
export default ActionsDropdown;
