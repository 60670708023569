export enum ElementType {
  QUIZ = 'quiz',
  TEST = 'test',
}

export enum ActivityStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  Archived = 'Archived',
}

export enum RoutesEnum {
  HOME = '/',
  API_AUTH_LOGIN = '/api/auth/login',
  ASSIGNMENTS = '/assignments',
  COURSES = '/courses',
  COURSES_COURSE_ID = '/courses/:courseId',
  PROFILE = '/profile',
  START_TEST = '/start-test',
  TEST_ATTEMPT_ATTEMPT_ID = '/test-attempt/:attemptId',
  REVIEW_TEST_ATTEMPT_ID = '/review-test/:attemptId',
  START_QUIZ = '/start-quiz',
  QUIZ_ATTEMPT_ATTEMPT_ID = '/quiz-attempt/:attemptId',
  JOIN_INVITE_ID = '/join/:inviteId',
  QUIZ_ATTEMPTS_QUIZ_SLUG = '/quiz-attempts/:quizSlug',
  TEST_ATTEMPTS_TEST_SLUG = '/test-attempts/:testSlug',
  CHECKOUT = '/checkout',
  CHECKOUT_SUCCESS = '/checkout/success',
}

export enum RoutesPermissionEnum {
  COURSES_USER_ID_COURSE_ID = '/courses/:userId/:courseId',
  INSPECT_TEST_ATTEMPT_ATTEMPT_ID = '/inspect-test-attempt/:attemptId',
  INSPECT_QUIZ_ATTEMPT_ATTEMPT_ID = '/inspect-quiz-attempt/:attemptId',
  QUESTIONS_BROWSER = '/questions-browser',
  QUESTIONS_BROWSER_DRAFTS = '/questions-browser/drafts',
  CREATE_QUESTION = '/create-question',
  EDIT_QUESTION_QUESTION_ID = '/edit-question/:questionId',
  QUIZZES_BROWSER = '/quizzes-browser',
  QUIZZES_BROWSER_SLUG = '/quizzes-browser/:slug',
  CREATE_QUIZ = '/create-quiz',
  TESTS_BROWSER = '/tests-browser',
  TESTS_BROWSER_TEST_SLUG = '/tests-browser/:testSlug',
  TESTS_BROWSER_TEST_SLUG_SECTION_ID_MODULE_ID = '/tests-browser/:testSlug/:sectionId/:moduleId',
  CREATE_TEST = '/create-test',
  SCRIPTS_BROWSER = '/scripts-browser',
  CREATE_SCRIPT = '/create-script',
  SCRIPTS_BROWSER_SCRIPT_ID = '/scripts-browser/:scriptId',
  COURSES_BROWSER = '/courses-browser',
  COURSES_BROWSER_ARCHIVE = '/courses-browser/archive',
  CREATE_COURSE = '/create-course',
  COURSES_BROWSER_COURSE_ID = '/courses-browser/:courseId',
  USERS_BROWSER = '/users-browser',
  USERS_BROWSER_USER_ID = '/users-browser/:userId',
  QUESTIONS_REPORTS = '/questions-reports',
  ANSWERS_VIEW = '/answers-view',
  ASSETS = '/assets',
  ASSETS_BROWSER = '/assets-browser',
  CREATE_ASSET = '/create-asset',
  ASSETS_BROWSER_ASSET_ID = '/assets-browser/:assetId',
  WATERMARK = '/watermark',
}

export enum NavBarEnum {
  QUESTIONS_BROWSER = RoutesPermissionEnum.QUESTIONS_BROWSER,
  TESTS_BROWSER = RoutesPermissionEnum.TESTS_BROWSER,
  QUIZZES_BROWSER = RoutesPermissionEnum.QUIZZES_BROWSER,
  COURSES_BROWSER = RoutesPermissionEnum.COURSES_BROWSER,
  USERS_BROWSER = RoutesPermissionEnum.USERS_BROWSER,
  ASSETS_BROWSER = RoutesPermissionEnum.ASSETS_BROWSER,
  SCRIPTS_BROWSER = RoutesPermissionEnum.SCRIPTS_BROWSER,
  QUESTIONS_REPORTS = RoutesPermissionEnum.QUESTIONS_REPORTS,
  TEACHER_ASSETS = RoutesPermissionEnum.ASSETS,
  ANSWERS_VIEW = RoutesPermissionEnum.ANSWERS_VIEW,
  WATERMARK = RoutesPermissionEnum.WATERMARK,
}

export enum WatermarkParamsEnum {
  ANGLE = 'angle',
  TEXT_SIZE = 'textSize',
  LOGO_SIZE = 'logoSize',
  TEXT_OPACITY = 'textOpacity',
  LOGO_OPACITY = 'logoOpacity',
  TEXT_COUNT = 'textCount',
  LOGO_INDEX = 'logoIndex',
  LOGO = 'logo',
  TEXT = 'text',
}

export enum EnvModEnum {
  PROD = 'production',
  DEV = 'development',
}
