import ButtonToggle from '../../../../components/common/button-toggle.tsx';
import { useWatermarkLocalParams } from './use-watermark-local-changes';

import LocalIcon from '../../../../assets/icons/local.svg?react';

const WatermarkLocalParams = (): JSX.Element => {
  const { onSubmit, isLoading, value } = useWatermarkLocalParams();

  return (
    <div>
      <h2 className="flex">
        <LocalIcon className={'mr-2'} /> Local changing
      </h2>
      <p className={'description mb-4 mt-2'}>
        These settings are visible exclusively to your admin account
      </p>

      <div
        className={'flex flex-col gap-5 rounded-md p-2'}
        style={{
          border: '1px solid rgba(100,100,100)',
          backgroundColor: 'rgb(240, 240, 240)',
        }}
      >
        <div
          className="flex justify-around"
          style={{
            pointerEvents: isLoading ? 'none' : 'auto',
            opacity: isLoading ? 0.5 : 1,
          }}
        >
          <ButtonToggle
            style={'flex'}
            state={value}
            setState={() => {
              onSubmit();
            }}
            label={'Show watermark ?'}
          />
        </div>
      </div>
    </div>
  );
};

export default WatermarkLocalParams;
